<!--
 * @FilePath: \zsw_new_edition\src\components\AuthorMore.vue
 * @Brief: 文件说明
 * @Version: 1.0
 * @Date: 2021-11-23 14:04:10
 * @Author: M
 * @Copyright: @M copyright description
 * @LastEditors: M
 * @LastEditTime: 2022-01-12 16:13:59
-->
<template>
  <div class="authorMoreBox">
    <div class="authorHeader df_Yc">
      <div class="authorAvatar">
        <img :src="authorInfo.avatar" alt="" />
      </div>
      <div>
        <p class="authorName">{{ authorInfo.username }}</p>
        <p class="c_99 f12">作品数据：{{ authorInfo.works }}</p>
      </div>
      <div class="dfc c_p goAuthor">
        <router-link
          :to="{ path: '/user/index', query: { uid: authorInfo.uid } }"
        >
          <span>查看全部</span>
          <i class="iconfont">&#xe665;</i>
        </router-link>
      </div>
    </div>
    <div class="authorWok" v-if="authorOther">
      <div
        :class="{ wokImg: type == 'material', suWokImg: type == 'su' || type == 'max' || type == 'schemeText', chartletWokImg: type == 'chartlet' }"
        v-for="(item, index) in authorOther"
        :key="index"
      >
        <router-link
          :to="{ path: path, query: { detailId: item.id } }"
          target="_blank"
        >
          <img ondragstart="return false" oncontextmenu="return false" :src="item.img || item.pic_str" alt="" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthorMore",
  props: ["authorInfo", "authorOther", "type"],
  data() {
    return {
      path: "",
    };
  },
  created() {
    this.path = this.$route.path;
  },
};
</script>

<style scoped>
.authorMoreBox .authorWok {
  margin-top: 0.3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.authorMoreBox .authorWok .wokImg {
  cursor: pointer;
  width: 1.14rem;
  height: 1.14rem;
  border-radius: 0.04rem;
  overflow: hidden;
  /*background: coral;*/
  margin-bottom: 0.1rem;
}
.wokImg img {
  width: 1.14rem;
  height: 1.14rem;
}

.authorMoreBox .authorWok .suWokImg {
  cursor: pointer;
  width: 1.14rem;
  height: 0.87rem;
  border-radius: 0.04rem;
  overflow: hidden;
  /*background: coral;*/
  margin-bottom: 0.1rem;
}
.suWokImg img {
  width: 1.14rem;
  height: 0.87rem;
}
.authorMoreBox .authorWok .chartletWokImg {
  cursor: pointer;
  width: 1.14rem;
  height: 0.87rem;
  border-radius: 0.04rem;
  overflow: hidden;
  /*background: coral;*/
  margin-bottom: 0.1rem;
}
.chartletWokImg img {
  width: 1.14rem;
  height: 0.87rem;
	object-fit: cover;
}

.goAuthor {
  font-size: 0.14rem;
  margin-left: auto;
  padding: 0.03rem 0.15rem;
  text-align: center;
  color: #333333;
  background: #fff6ef;
  border-radius: 0.1rem;
}

.authorHeader .authorAvatar {
  margin-right: 0.15rem;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  overflow: hidden;
  /*background: coral;*/
}
.authorAvatar img {
  width: 100%;
}
.authorHeader .authorName {
  color: #040404;
  font-size: 0.16rem;
  font-weight: bold;
}

.authorMoreBox {
  width: 100%;
  padding: 0.2rem;
}
</style>